<template>
  <section>
    <confirmation-dialog
      ref="showApprovalDialog"
      title="Deseja aprovar a licença?"
      toApprove
    />

    <confirmation-dialog
      ref="showRefusalDialog"
      width="450"
      title="Deseja recusar a licença?"
      label="Escreva uma justificativa"
      maxlength="500"
    />

    <v-dialog
      v-model="show"
      width="600"
      persistent
    >
      <v-toolbar class="headline green darken-2 white--text">
        <v-layout align-center justify-space-between>
          <span>
            Aprovação de Licenças
          </span>
          <v-btn flat icon @click="close">
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>

        </v-layout>
      </v-toolbar>

      <v-card width="600">
        <v-container grid-list-lg style="max-height: 550px; overflow-y: auto">
          <v-layout wrap>
            <v-flex xs12>
              <h3>Nome: </h3> <span>{{ selectedLicense.person.name }}</span>
            </v-flex>
            <v-flex xs12>
              <h3>CPF/CNPJ: </h3> <span>{{ formatCpfCnpj(selectedLicense.person.cpf_cnpj) }}</span>
            </v-flex>
            <v-flex xs12>
              <v-divider/>
            </v-flex>

            <v-flex xs12>
              <v-card>
                <v-card-text>
                  <v-flex xs12>
                    <v-layout row wrap justify-center>
                      <strong>
                        <span>Licença como {{ translateLicenseType(selectedLicense.license_type) }}
                        <v-chip :color="licenseStatusColor(selectedLicense.status)" text-color="white">
                          {{ licenseStatusText(selectedLicense.status) }}
                        </v-chip>
                        </span>
                      </strong>
                    </v-layout>
                    <v-flex xs12>
                      <strong>Nº do Processo:</strong>
                      {{ selectedLicense.process_number }}
                    </v-flex>
                    <v-flex xs12>
                      <strong>Nº da Licença: </strong>
                      {{ selectedLicense.number }}
                    </v-flex>
                    <v-flex xs12>
                      <strong>Validade: </strong>
                      {{ formatDate(selectedLicense.due_date) }}
                    </v-flex>
                    <v-flex xs12 v-if="!!selectedLicense.reason">
                      <strong>justificativa: </strong>
                      {{ selectedLicense.reason }}
                    </v-flex>
                    <v-flex xs12>
                      <strong v-if="!selectedLicense.file.url">Nenhum arquivo adicionado</strong>
                      <a v-else :href="selectedLicense.file.url" target="_blank">
                        Visualizar Licença
                      </a>
                    </v-flex>
                    <v-flex xs12 v-if="canApproveLicense(selectedLicense)">
                      <v-layout align-end justify-end row fill-height>
                        <v-btn
                          color="error"
                          outline
                          :loading="loading"
                          @click="openRefusalDialog"
                        >
                          Recusar Licença
                        </v-btn>
                        <v-btn
                          color="primary"
                          :loading="loading"
                          @click="openApprovalDialog"
                        >
                          Aprovar Licença
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>

        <v-divider />

        <v-card-actions>
          <v-layout justify-end>
            <v-btn
              flat
              @click="close"
            >
              Fechar
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>

import formatCpfCnpj from '@/utils/formatCpfCnpj';
import LicenseService from '@/services/license-service';
import ConfirmationDialog from '@/components/settings/ConfirmationDialog';

export default {
  name: 'PendencyDialog',
  props: ['show', 'selectedLicense'],
  components: {
    ConfirmationDialog,
  },
  data() {
    return {
      formatCpfCnpj,
      valid: false,
      loading: false,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    approveLicense(license) {
      const vm = this;
      const licenseId = license.id;
      vm.loading = true;

      LicenseService
        .approveLicense({
          licenseId,
        })
        .then(() => {
          vm.selectedLicense.status = 'approved';
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          vm.loading = false;
        });
    },
    refuseLicense(license, reason) {
      const vm = this;
      const licenseId = license.id;
      vm.loading = true;

      LicenseService
        .refuseLicense({
          licenseId,
          reason,
        })
        .then(() => {
          vm.selectedLicense.status = 'not_approved';
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-alert-outline',
          });
        })
        .finally(() => {
          vm.loading = false;
        });
    },
    translateLicenseType(licenseType) {
      return {
        receiver: 'Destinador',
        transporter: 'Transportador',
        temporary_storage: 'Armazenador Temporário',
      }[licenseType];
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    licenseStatusColor(status) {
      return {
        approved: 'green',
        waiting_approval: 'amber',
        waiting_to_be_sent: 'grey',
        not_approved: 'red',
      }[status];
    },
    licenseStatusText(status) {
      return {
        approved: 'Licença aprovada',
        waiting_approval: 'Aguardando Aprovação',
        waiting_to_be_sent: 'Aguardando Envio',
        not_approved: 'Licença Recusada',
      }[status];
    },
    canApproveLicense(l) {
      return (l.status === 'waiting_approval' && l.file.url && l.number && l.process_number && l.due_date);
    },
    openApprovalDialog() {
      this.$refs.showApprovalDialog.open((e) => {
        this.approveLicense(this.selectedLicense);
      });
    },
    openRefusalDialog() {
      this.$refs.showRefusalDialog.open((reason) => {
        this.refuseLicense(this.selectedLicense, reason);
      });
    },
  },
};
</script>
