var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("confirmation-dialog", {
        ref: "showApprovalDialog",
        attrs: { title: "Deseja aprovar a licença?", toApprove: "" },
      }),
      _c("confirmation-dialog", {
        ref: "showRefusalDialog",
        attrs: {
          width: "450",
          title: "Deseja recusar a licença?",
          label: "Escreva uma justificativa",
          maxlength: "500",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { width: "600", persistent: "" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-toolbar",
            { staticClass: "headline green darken-2 white--text" },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-space-between": "" } },
                [
                  _c("span", [
                    _vm._v("\n          Aprovação de Licenças\n        "),
                  ]),
                  _c(
                    "v-btn",
                    { attrs: { flat: "", icon: "" }, on: { click: _vm.close } },
                    [
                      _c("v-icon", { attrs: { color: "white" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card",
            { attrs: { width: "600" } },
            [
              _c(
                "v-container",
                {
                  staticStyle: { "max-height": "550px", "overflow-y": "auto" },
                  attrs: { "grid-list-lg": "" },
                },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "" } },
                    [
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", [_vm._v("Nome: ")]),
                        _c("span", [
                          _vm._v(_vm._s(_vm.selectedLicense.person.name)),
                        ]),
                      ]),
                      _c("v-flex", { attrs: { xs12: "" } }, [
                        _c("h3", [_vm._v("CPF/CNPJ: ")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.formatCpfCnpj(
                                _vm.selectedLicense.person.cpf_cnpj
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [_c("v-divider")],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "" } },
                        [
                          _c(
                            "v-card",
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c(
                                    "v-flex",
                                    { attrs: { xs12: "" } },
                                    [
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            row: "",
                                            wrap: "",
                                            "justify-center": "",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  "Licença como " +
                                                    _vm._s(
                                                      _vm.translateLicenseType(
                                                        _vm.selectedLicense
                                                          .license_type
                                                      )
                                                    ) +
                                                    "\n                      "
                                                ),
                                                _c(
                                                  "v-chip",
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.licenseStatusColor(
                                                          _vm.selectedLicense
                                                            .status
                                                        ),
                                                      "text-color": "white",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.licenseStatusText(
                                                            _vm.selectedLicense
                                                              .status
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("strong", [
                                          _vm._v("Nº do Processo:"),
                                        ]),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.selectedLicense.process_number
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("strong", [
                                          _vm._v("Nº da Licença: "),
                                        ]),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.selectedLicense.number) +
                                            "\n                  "
                                        ),
                                      ]),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _c("strong", [_vm._v("Validade: ")]),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.formatDate(
                                                _vm.selectedLicense.due_date
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                      !!_vm.selectedLicense.reason
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c("strong", [
                                                _vm._v("justificativa: "),
                                              ]),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.selectedLicense.reason
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        !_vm.selectedLicense.file.url
                                          ? _c("strong", [
                                              _vm._v(
                                                "Nenhum arquivo adicionado"
                                              ),
                                            ])
                                          : _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: _vm.selectedLicense.file
                                                    .url,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      Visualizar Licença\n                    "
                                                ),
                                              ]
                                            ),
                                      ]),
                                      _vm.canApproveLicense(_vm.selectedLicense)
                                        ? _c(
                                            "v-flex",
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                "v-layout",
                                                {
                                                  attrs: {
                                                    "align-end": "",
                                                    "justify-end": "",
                                                    row: "",
                                                    "fill-height": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "error",
                                                        outline: "",
                                                        loading: _vm.loading,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openRefusalDialog,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Recusar Licença\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-btn",
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        loading: _vm.loading,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openApprovalDialog,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        Aprovar Licença\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-layout",
                    { attrs: { "justify-end": "" } },
                    [
                      _c(
                        "v-btn",
                        { attrs: { flat: "" }, on: { click: _vm.close } },
                        [_vm._v("\n            Fechar\n          ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }