import axios from 'axios';
import AS from './auth-service';

const licenseService = {

  getLicenses({
    q = '',
    page = 1,
    limit = 20,
    status,
  }) {
    const params = {
      q,
      page,
      limit,
      status,
    };
    return axios.get('licenses', { params, headers: AS.getDefaultHeaders().headers });
  },

  approveLicense({ licenseId }) {
    return axios.patch(`auth/license/${licenseId}/approval`, { status: 'approved' }, AS.getDefaultHeaders());
  },

  refuseLicense({ licenseId, reason }) {
    return axios.patch(`auth/license/${licenseId}/approval`, { status: 'not_approved', reason }, AS.getDefaultHeaders());
  },

  update(licenseId, payload) {
    return axios.patch(`licenses/${licenseId}`, payload, AS.getDefaultHeaders());
  },

  destroyLicense(licenseId) {
    return axios.delete(`licenses/${licenseId}/destroy_license`, AS.getDefaultHeaders());
  },

};

export default licenseService;
